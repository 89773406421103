import React, { Component } from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
export class MapContainer extends Component {
  render() {
    return (
      <Map
        className="map"
        google={this.props.google}
        zoom={17}
        initialCenter={{
          lat: 45.296187,
          lng: 19.835804,
        }}
      >
        <Marker position={{ lat: 45.296187, lng: 19.835804 }}></Marker>
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDn55hyL5duvCQgaQCbrMIwnvBlJXJbCrA",
})(MapContainer)
