import React, { Component } from "react"
import Layout from "../components/layout"
import "aos/dist/aos.css"
import AOS from "aos"
import Mapa from "../components/Mapa"
import { useEffect } from "react"
import Mapa2 from "../components/Mapa2"
import Mapa3 from "../components/Mapa3"
import Head from "../components/head"

const Kontakt = () => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  return (
    <Layout>
      <Head title="Kontakt" />
      <div style={{ overflowY: "hidden" }}>
        <section
          className="hero karijera-hero is-medium-with-navbar has-background-link "
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <div className="hero-body has-text-white has-text-centered">
            <div className="columns is-multiline">
              <div className="column is-full">
                <h1 className="is-size-1 is-size-3-mobile">Kontakt</h1>
              </div>
              <div className="column is-full">
                <p className="is-size-5 is-size-6-mobile">
                  <i className="fas fa-phone-alt"></i> Novi Sad : +381 (0)
                  21/641 4818 ; 021/478 0340 ; 021/641 3337
                </p>
              </div>
              <div className="column is-full">
                <p className="is-size-5 is-size-6-mobile">
                  <i className="fas fa-phone-alt"></i> Gornji Milanovac : +381
                  (0) 32/718083 ; 032/718136
                </p>
              </div>
              <div className="column is-full">
                <p className="is-size-5 is-size-6-mobile">
                  <i className="fas fa-envelope"></i> Email :
                  office@dakcomerc.co.rs
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div style={{ overflowY: "hidden" }}>
        <section
          className="maps-section container"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <div
            className="columns is-mobile is-hidden-mobile has-text-centered"
            style={{ marginBottom: "30px" }}
          >
            <div className="column">
              <p className="is-size-3">Maloprodaja i Centrala</p>
              <p>Temerinski put 21, Novi Sad</p>
            </div>
            <div className="column">
              <p className="is-size-3">Veleprodaja</p>
              <p>Arkadija Varađanina 63, Novi sad</p>
            </div>
            <div className="column">
              <p className="is-size-3">Fabrika i servisni centar</p>
              <p>Radovana Grkovića 2, Gornji Milanovac</p>
            </div>
          </div>
          <div className="columns is-multiline has-text-centered">
            <div className="column is-hidden-desktop">
              <p className="is-size-3">Maloprodaja i Centrala</p>
              <p>Temerinski put 21, Novi Sad</p>
            </div>
            <div className="column box map-column">
              <Mapa />
            </div>
            <div className="column is-hidden-desktop">
              <p className="is-size-3">Veleprodaja</p>
              <p>Arkadija Varađanina 63, Novi sad</p>
            </div>
            <div className="column box map-column">
              <Mapa2 />
            </div>
            <div className="column is-hidden-desktop">
              <p className="is-size-3">Fabrika i servisni centar</p>
              <p>Radovana Grkovića 2, Gornji Milanovac</p>
            </div>
            <div className="column box map-column">
              <Mapa3 />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Kontakt
